const defineContants = function () {
  return {
    SELECTORS: {
      inputs: {
        name: '[name="pet[name]"]',
        gender: '[name="pet[gender]"]',
        breed: '[name="pet[breed][]"]',
        birth_year: '[name="pet[birth_year]"]',
        birth_month: '[name="pet[birth_month]"]',
        weight: '[name="pet[weight]"]',
        body: '[name="pet[body]"]',
        activity: '[name="pet[activity]"]',
        health_issues: '[name="pet[health_issues][]"]',
        allergies: '[name="pet[allergies][]"]'
      },
      profile: {
        header: '.profile-header',
        message: '.profile-message',
        welcome: '.profile-welcome',
        title: '.profile-header-title',
        content: '.profile-content',
        overview: '.profile-overview',
        info: '.profile-info',
        line: '.profile-line',
        gender: '.profile-gender',
        breed: '.profile-breed',
        birth_year: '.profile-birth-year',
        birth_month: '.profile-birth-month',
        weight: '.profile-weight',
        body: '.profile-body',
        activity: '.profile-activity',
        health_issues: '.profile-health-issues',
        allergies: '.profile-allergies',
      }
    },

    DOM: {
      profile: document.querySelector('.profile'),
      data: document.querySelector('.profile-data'),
      header: document.querySelector('.profile-header'),
      content: document.querySelector('.profile-content'),
      welcome: document.querySelector('.profile-welcome'),
    },

    STEPS: {
      breed: ['breed'],
      info: ['name', 'gender'],
      birth_year: ['birth_year'],
      birth_month: ['birth_month'],
      weight: ['weight'],
      body: ['body'],
      activity: ['activity'],
      health_issues: ['health_issues'],
      allergies: ['allergies']
    },

    ICONS: {
      breed: {
        viewBox: '0 0 30 30.428',
        id: 'svg_paw',
        classes: ['icon', 'profile-icon']
      },
      gender: {
        m: {
          viewBox: '0 0 94.895 94.834',
          id: 'gender_male',
          classes: ['icon', 'profile-icon', 'profile-male']
        },
        f: {
          viewBox: '0 0 68.237 116.217',
          id: 'gender_female',
          classes: ['icon', 'profile-icon', 'profile-female']
        }
      },
      'birth_year': {
        viewBox: '0 0 30 30',
        id: 'calendar',
        classes: ['icon', 'profile-icon']
      }
    }
  }
}

const helperFunctions = (function (defineConstants) {
  const { STEPS } = defineConstants();

  return {
    clearElement: function (element) {
      element.innerHTML = '';
    },
    removeElement: function (selector) {
      if (!selector) return;

      const element = document.querySelector(selector);
      if (!element) return;

      element.parentNode.removeChild(element);
    },
    createSvgLink: function (props) {
      const { id, viewBox, classes } = props;
      const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
        useElement = document.createElementNS('http://www.w3.org/2000/svg', 'use');

      svgElement.setAttribute('viewBox', viewBox);
      svgElement.setAttribute('preserveAspectRatio', 'xMidYMid');
      if (classes) {
        svgElement.classList.add(...classes);
      }

      useElement.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#' + id);
      useElement.setAttribute('href', '#' + id);

      svgElement.appendChild(useElement);

      return svgElement;
    },
    getSelectedOptionText: function (select) {
      const value = select.options[select.selectedIndex].textContent.trim();

      return value;
    },
    getSelectedOptionsText: function (select) {
      const selected = [...select.options].filter(option => option.selected);
      const values = selected.map(option => option.textContent.trim());

      return values;
    },
    getCheckedValuesLabel: function (elements) {
      const checked = [...elements].filter(element => element.checked);
      const values = checked.map(element => element.nextElementSibling.textContent.trim());

      return values;
    },
    checkIfEmptyObj: function (obj) {
      return obj
        && Object.keys(obj).length === 0
        && Object.getPrototypeOf(obj) === Object.prototype
    },
    checkIfAnyValueInObj: function (obj) {
      return Object.values(obj).some(value => Boolean(value));
    },
    redirectToStep: function (inputIdentifier) {
      if (!inputIdentifier) return;
      const step = Object.keys(STEPS).filter(function (stepKey) { return STEPS[stepKey].includes(inputIdentifier) })[0];
      if (step && Object.keys(STEPS).includes(step)) {
        window.location.replace(step);
      }
    }
  }
})(defineContants);

const profileFunctions = function (helpers, defineConstants) {
  const profile = document.querySelector('.profile');
  if (!profile) return;

  const { SELECTORS, DOM, ICONS } = defineConstants();

  const FUNC_CREATE_ELEMENTS = {
    content: function () {
      const contentDOM = document.querySelector(SELECTORS.profile.content);
      if (contentDOM) return contentDOM;

      const content = document.createElement('div');
      content.classList.add('card-body', 'profile-content');

      DOM.data.append(content);

      return content;
    },

    overview: function (body) {
      const overviewDOM = document.querySelector(SELECTORS.profile.overview);
      if (overviewDOM) return overviewDOM;

      const overview = document.createElement('ul');
      overview.classList.add('profile-overview');
      helpers.removeElement(SELECTORS.profile.welcome);

      DOM.content.classList.add('profile-content-visible')

      body.append(overview);

      return overview;
    },

    line: function () {
      const lineDOM = document.querySelector(SELECTORS.profile.line);
      if (lineDOM) return lineDOM;

      const line = document.createElement('hr');
      line.classList.add('profile-line');

      return line;
    },

    info: function (body) {
      const infoDOM = document.querySelector(SELECTORS.profile.info);
      if (infoDOM) return infoDOM;

      const info = document.createElement('ul');
      info.classList.add('profile-info');

      const line = this.line();

      body.append(line);
      body.append(info);

      return info;
    },

    message: function () {
      const message = document.createElement('p');
      message.classList.add('profile-message');
      message.textContent = I18n.profile.welcome_message;

      DOM.header.append(message);
    },

    element: function (step, parent = true) {
      helpers.removeElement(SELECTORS.profile[step]);

      const subElement = document.createElement('span');
      FUNC_ADD_EVENTS.redirect(subElement, step);
      if (!parent) {
        subElement.classList.add(SELECTORS.profile[step].substring(1));
        return subElement;
      }

      let element = null;
      if (parent) {
        element = document.createElement('li');
        element.classList.add(SELECTORS.profile[step].substring(1));
      }

      return [element, subElement];
    },

    title: function (name) {
      const titleDOM = document.querySelector(SELECTORS.profile.title);
      if (titleDOM) return titleDOM;

      const profileName = document.createElement('span');
      profileName.classList.add('profile-name');
      const profileTitle = document.createElement('p');
      profileTitle.classList.add('profile-header-title');

      if (name) {
        profileName.textContent = name;
        profileTitle.append(profileName);
        profileTitle.innerHTML += I18n.profile.title;
      }
      FUNC_ADD_EVENTS.redirect(profileTitle, 'name');

      DOM.header.prepend(profileTitle);
    },

    gender: function (gender) {
      const [genderElement, spanElement] = this.element('gender');

      spanElement.textContent = I18n.profile.gender.labels[gender];

      const svgElement = helpers.createSvgLink(ICONS.gender[gender]);

      genderElement.prepend(svgElement);
      genderElement.append(spanElement);

      return genderElement;
    },

    breed: function (breeds) {
      const [breedElement, spanElement] = this.element('breed');
      if (!breeds.length) return;

      const content = breeds.length > 1 ? I18n.profile.breed.mixed : breeds.join(', ');
      spanElement.textContent = content;

      const svgElement = helpers.createSvgLink(ICONS.breed);

      breedElement.append(svgElement);
      breedElement.append(spanElement);

      return breedElement;
    },

    birth_year: function (birth_year) {
      const [birth_yearElement, spanElement] = this.element('birth_year');

      spanElement.textContent = birth_year;

      const svgElement = helpers.createSvgLink(ICONS.birth_year);
      birth_yearElement.append(svgElement);
      if (petProfileSessionData.birth_month) {
        const birth_monthElement = FUNC_CREATE_ELEMENTS.birth_month(petProfileSessionData.birth_month);
        birth_yearElement.append(birth_monthElement);
      }
      birth_yearElement.append(spanElement);

      return birth_yearElement;
    },

    birth_month: function (birth_month) {
      const birth_monthElement = this.element('birth_month', false);

      birth_monthElement.textContent = birth_month;

      return birth_monthElement;
    },

    weight: function (weight) {
      const [weightElement, spanElement] = this.element('weight');

      let content = I18n.profile.weight.label;

      content = content.replace('%{weight}', Math.round(weight * 100) / 100).replace('.', ',');

      spanElement.innerHTML = content;

      weightElement.append(spanElement);

      return weightElement;
    },

    body: function (body) {
      const bodyElement = this.element('body', false);

      let content = I18n.profile.body.label;
      content = content.replace('%{body}', body)

      bodyElement.textContent = content;

      return bodyElement;
    },

    activity: function (activity) {
      const [activityElement, spanElement] = this.element('activity');

      let content = I18n.profile.activity.label;
      content = content.replace('%{activity}', activity)

      const gender = petProfileSessionData.gender;

      const pronoun = gender ? I18n.profile.gender.pronoun[gender] : `${Object.values(I18n.profile.gender.pronoun).join('/')}`;
      content = content.replace('%{pronoun}', pronoun);

      spanElement.textContent = content;

      activityElement.append(spanElement);

      return activityElement;
    },

    health_issues: function (health_issues) {
      const [health_issuesElement, spanElement] = this.element('health_issues');

      let content = health_issues.length > 0 ? I18n.profile.health_issues.with : I18n.profile.health_issues.without;

      const gender = petProfileSessionData.gender;

      const pronoun = gender ? I18n.profile.gender.pronoun[gender] : `${Object.values(I18n.profile.gender.pronoun).join('/')}`;
      content = content.replace('%{pronoun}', pronoun);

      spanElement.textContent = content;

      health_issuesElement.append(spanElement);

      return health_issuesElement;
    },

    allergies: function (allergies) {
      const [allergiesElement, spanElement] = this.element('allergies');

      let content = allergies.length > 0 ? I18n.profile.allergies.with : I18n.profile.allergies.without;

      const gender = petProfileSessionData.gender;

      const pronoun = gender ? I18n.profile.gender.pronoun[gender] : `${Object.values(I18n.profile.gender.pronoun).join('/')}`;
      content = content.replace('%{pronoun}', pronoun);

      spanElement.textContent = content;

      allergiesElement.append(spanElement);

      return allergiesElement;
    }
  }

  const FUNC_UPDATE_ELEMENTS = {
    name: function () {
      const name = document.querySelector(SELECTORS.inputs.name);
      if (!name) return;

      name.addEventListener('input', function (e) {
        FUNC_LOAD_ELEMENTS.name(e.target.value);

        const profileSpan = DOM.profile.querySelectorAll('.profile-name');
        Array.prototype.forEach.call(profileSpan, function (ps) {
          ps.textContent = e.target.value;
        });
      })
    },

    gender: function () {
      const genders = document.querySelectorAll(SELECTORS.inputs.gender);
      if (!genders.length) return;

      Array.prototype.forEach.call(genders, function (gender) {
        gender.addEventListener('change', function (e) {
          FUNC_LOAD_ELEMENTS.gender(e.target.value);
        })
      })
    },

    breed: function () {
      const breed = document.querySelector(SELECTORS.inputs.breed);
      if (!breed) return;

      breed.addEventListener('change', function (e) {
        const values = helpers.getSelectedOptionsText(breed);
        FUNC_LOAD_ELEMENTS.breed(values);
      })
    },

    birth_year: function () {
      const birth_year = document.querySelector(SELECTORS.inputs.birth_year);
      if (!birth_year) return;

      birth_year.addEventListener('change', function (e) {
        FUNC_LOAD_ELEMENTS.birth_year(e.target.value);
      });
    },

    birth_month: function () {
      const birth_month = document.querySelector(SELECTORS.inputs.birth_month);
      if (!birth_month) return;

      birth_month.addEventListener('change', function (e) {
        const values = helpers.getSelectedOptionText(birth_month);
        FUNC_LOAD_ELEMENTS.birth_month(values);
      })
    },

    weight: function () {
      const weight = document.querySelector(SELECTORS.inputs.weight);
      if (!weight) return;

      weight.addEventListener('input', function (e) {
        FUNC_LOAD_ELEMENTS.weight(e.target.value);
      })
    },

    body: function () {
      const bodies = document.querySelectorAll(SELECTORS.inputs.body);
      if (!bodies.length) return;

      Array.prototype.forEach.call(bodies, function (body) {
        body.addEventListener('click', function () {
          const bodyValue = body.parentNode.querySelector('label span:first-child').textContent;
          FUNC_LOAD_ELEMENTS.body(bodyValue);
        });
      });
    },

    activity: function () {
      const activities = document.querySelectorAll(SELECTORS.inputs.activity);
      if (!activities.length) return;

      Array.prototype.forEach.call(activities, function (activity) {
        activity.addEventListener('click', function () {
          const activityValue = activity.parentNode.querySelector('label span:first-child').textContent;
          FUNC_LOAD_ELEMENTS.activity(activityValue);
        });
      });
    },

    health_issues: function () {
      const health_issues = document.querySelectorAll(SELECTORS.inputs.health_issues);
      if (!health_issues.length) return;

      Array.prototype.forEach.call(health_issues, function (healthIssue) {
        healthIssue.addEventListener('change', function () {
          const values = helpers.getCheckedValuesLabel(health_issues);
          FUNC_LOAD_ELEMENTS.health_issues(values);
        });
      });
    },

    allergies: function () {
      const allergies = document.querySelectorAll(SELECTORS.inputs.allergies);
      if (!allergies.length) return;

      Array.prototype.forEach.call(allergies, function (allergie) {
        allergie.addEventListener('change', function () {
          const values = helpers.getCheckedValuesLabel(allergies);
          FUNC_LOAD_ELEMENTS.allergies(values);
        });
      });
    }
  };

  const FUNC_LOAD_ELEMENTS = {
    step: function (step, value, elementIdentifier, position) {
      const contentElement = FUNC_CREATE_ELEMENTS.content();
      const element = FUNC_CREATE_ELEMENTS[elementIdentifier](contentElement);

      const stepElement = FUNC_CREATE_ELEMENTS[step](value);
      if (stepElement) element.insertBefore(stepElement, element.children[position]);
    },

    name: function (name) {
      if (name === '') {
        helpers.clearElement(DOM.header);
        FUNC_CREATE_ELEMENTS.message();
      } else {
        helpers.removeElement(SELECTORS.profile.message);
        helpers.removeElement(SELECTORS.profile.welcome);
        FUNC_CREATE_ELEMENTS.title(name);
      }
    },

    breed: function (breeds) {
      this.step('breed', breeds, 'overview', 0);
    },

    gender: function (gender) {
      this.step('gender', gender, 'overview', 1);
    },

    birth_year: function (birth_year) {
      this.step('birth_year', birth_year, 'overview', 2);
    },

    birth_month: function (birth_month) {
      const birth_yearElement = document.querySelector(SELECTORS.profile.birth_year);

      if (birth_yearElement) {
        const birth_monthElement = FUNC_CREATE_ELEMENTS.birth_month(birth_month);
        birth_yearElement.insertBefore(birth_monthElement, birth_yearElement.children[1]);
      }
    },

    weight: function (weight) {
      this.step('weight', weight, 'info', 0);
    },

    body: function (body) {
      const weightElement = document.querySelector(SELECTORS.profile.weight);

      if (weightElement) {
        const bodyElement = FUNC_CREATE_ELEMENTS.body(body);
        weightElement.append(bodyElement);
      }
    },

    activity: function (activity) {
      this.step('activity', activity, 'info', 2);
    },

    health_issues: function (health_issues) {
      this.step('health_issues', health_issues, 'info', 3);
    },

    allergies: function (allergies) {
      this.step('allergies', allergies, 'info', 4);
    }
  };

  const FUNC_ADD_EVENTS = {
    redirect: function (element, step) {
      element.classList.add('profile-href');
      element.addEventListener('click', function (e) {
        e.stopPropagation();
        helpers.redirectToStep(step)
      });
    }
  };

  function loadProfile() {
    DOM.profile.classList.add('profile-visible');

    if (helpers.checkIfEmptyObj(petProfileSessionData)) return;

    Object.keys(petProfileSessionData).forEach(petKey => {
      const petValue = petProfileSessionData[petKey];

      if (petValue) FUNC_LOAD_ELEMENTS[petKey](petValue);
    });
  }

  function updateProfile() {
    Object.values(FUNC_UPDATE_ELEMENTS).map(updateFunction => {
      if (typeof updateFunction === 'function') {
        updateFunction.call();
      }
    });
  }

  return {
    init: function () {
      loadProfile();
      updateProfile();
    }
  }
};


document.addEventListener('DOMContentLoaded', function () {
  var body = document.querySelector('body');

  if (/pet-configurator/.test(body.classList)) profileFunctions(helperFunctions, defineContants).init();
});

